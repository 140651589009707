
import { catchError, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { Config } from "../config";
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
declare var toastr: any

@Injectable()

export class ClientService {

    configUrl = new Config().apiUrl

    constructor(private http: HttpClient) { }

    saveClientData(data) {
        return this.http.post(this.configUrl + '/client', { client: data }).pipe(
            catchError(err => {
                return Promise.reject(err)
            }))
    }

    updateClientData(data) {
        return this.http.put(this.configUrl + '/client/', { client: data }).pipe(
            catchError(err => {
                return Promise.reject(err)
            }))
    }

    getClients() {
        return this.http.get(this.configUrl + '/client')
    }

    getPartialClients(p, query) {
        return this.http.get(this.configUrl + '/client/partialClient', { params: { query: JSON.stringify(query), pageNO: p } })
    }


    getClientDetails(clientID) {
        return this.http.get(this.configUrl + '/client/' + clientID)
    }

    getViewClientsById(clientID) {
        return this.http.get(this.configUrl + '/client/queryClient',  { params: {query:JSON.stringify(clientID)}})
    }

    sendEmail(clientData) {
        return this.http.post(this.configUrl + '/client/email', clientData)
    }

    getClientName(clientEmailID) {
        return this.http.post(this.configUrl + '/client/clientName', { accountEmail: clientEmailID })
    }

    deleteClient(clientID) {
        return this.http.delete(this.configUrl + '/client/' + clientID)
    }

    sendPricingData(data) {
        return this.http.post(this.configUrl + '/client/pricing', { pricing: data })
    }

    getClientIDList() {
        return this.http.get(this.configUrl + '/client/clientIDList')
    }

    saveProducts(productData) {
        return this.http.post(this.configUrl + '/manage', productData)
    }

    getProducts() {
        return this.http.get(this.configUrl + '/manage')
    }

    getProductsBySegments(type) {
        return this.http.get(this.configUrl + '/manage',
        { params: {query:JSON.stringify(type)} })
    }

    deleteProduct(productID) {
        return this.http.delete(this.configUrl + '/manage/' + productID)
    }

    updateProduct(productID, prodData) {
        return this.http.put(this.configUrl + '/manage/' + productID, prodData)
    }

    getProductIDList() {
        return this.http.get(this.configUrl + '/manage/productIDList')
    }

    createProductIDList(data) {
        return this.http.post(this.configUrl + '/manage/productIDList', data)
    }

    getClientList(){
        return this.http.post(this.configUrl + '/client/getAllClientName', {'id': '1'})
    }

    validateClientId(clientID) {
        console.log({ params: {query:JSON.stringify(clientID)}})
        return this.http.get(this.configUrl + '/employee/employeeExistOrNot',  { params: {query:JSON.stringify(clientID)}})
    }
}